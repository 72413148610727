.auth-component {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1f1f1f; /* Same deep purple background as the table */
}

.login-container {
    width: 300px;
    padding: 2rem;
    background-color: #292929; /* Dark background for the form, similar to table */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Soft shadow */
    color: #fff; /* White text */
}

.login-header h1 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.input-container {
    margin-bottom: 1rem;
    background: #333333; /* Slightly lighter gray for input backgrounds, as in table borders */
    border-radius: 20px;
    overflow: hidden;
}

.input-container input {
    width: 100%;
    padding: 1rem;
    background: transparent;
    border: none;
    color: #fff; /* White text */
}

.input-container input::placeholder {
    color: #b3b3cc; /* Light grey for placeholder text, same as before */
}

.button-container {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.button-container button {
    padding: 1rem 2rem;
    border: none;
    border-radius: 20px;
    color: #fff; /* White text */
    background: #352481; /* Purple color for buttons, matching the table headers */
    cursor: pointer;
    transition: transform 0.2s;
}

.button-container button:hover {
    transform: translateY(-3px); /* Slight lift effect on hover */
}

.link-container {
    text-align: center;
}

.link-container a {
    color: #b3b3cc; /* Light grey for links, same as before */
    text-decoration: none;
    transition: color 0.2s;
}

.link-container a:hover {
    color: #dcdcff; /* Slightly lighter grey on hover, same as before */
}

.input-container input:focus {
    outline: 2px solid #352481; /* Adjust the color to match your theme */
    outline-offset: 2px;
}

.button-container {
    display: flex;
    justify-content: center; /* Align button to the center */
    margin-bottom: 1rem;
}
