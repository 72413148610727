.sheet-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 колонки с одинаковой шириной */
    gap: 10px;
    /* Промежуток между элементами сетки */
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    margin-bottom: 10px;
    /* Дополнительный отступ снизу */
}

.add-sheet-container {
    display: flex;
    justify-content: left;
    padding-left: 15px;
}

.sheet-button {
    background-color: #352481;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    /* Отступы, совпадающие с кнопками удаления */
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0;
    /* Убираем внешние отступы */
    height: 48px;
    /* Задаем фиксированную высоту, совпадающую с элементами сетки */
}


.sheet-button {
    background-color: #352481;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px 0;
    margin-left: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sheet-button:hover {
    background-color: #7e57c2;
}

.sheet-input {
    background-color: #333333;
    color: #ffffff;
    border: 1px solid #555555;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.sheet-input:focus {
    outline: none;
    border-color: #352481;
}

.sheet-item-container {
    display: flex;
    align-items: center;
    /* Выравниваем элементы по центру по вертикали */
    justify-content: space-between;
    /* Располагаем элементы на максимальном удалении друг от друга */
    background-color: #292929;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: transform 0.3s;
}

.sheet-item-container:hover {
    transform: translateY(-5px);
}

.delete-button {
    background-color: #b71c1c;
    /* Красный фон для кнопки удаления */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: #f44336;
    /* Светлый красный фон при наведении */
}

.sheet-item {
    background-color: #292929;
    color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s;
}

.sheet-item:hover {
    transform: translateY(-5px);
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s;
}

.modal-backdrop.active {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s;
}

.modal {
    background-color: rgba(41, 41, 41, 0.8);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    animation: zoomIn 0.5s;
}

@keyframes zoomIn {
    from {
        transform: scale(0);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes zoomOut {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0);
        opacity: 0;
    }
}

.modal-close-button {
    align-self: flex-end;
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}

.modal-title {
    font-size: 1.2rem;
    color: #fff;
}

