.hot-table {
    --hot-background-color: #1f1f1f;
    --hot-border-color: #333;
    --hot-text-color: #fff;
    --hot-primary-color: #352481;
    
}

.htCore {
    background-color: var(--hot-background-color);
    color: var(--hot-text-color);
    border-collapse: separate;
}

.htCore .ht_master .wtHolder {
    border: 2px solid var(--hot-border-color);
}


.htCore th,
.htCore td {
    border-right: 1px solid var(--hot-border-color);
    border-bottom: 1px solid var(--hot-border-color);
}


.htCore th {
    background-color: var(--hot-primary-color);
    color: var(--hot-text-color); /* Ensure text in headers is white */
    border-right: 1px solid var(--hot-header-border-color);
    border-bottom: 1px solid var(--hot-header-border-color);
}

.htCore td {
    background-color: var(--hot-background-color);
    color: var(--hot-text-color);
}

.htCore a {
    color: var(--hot-text-color);
    text-decoration: none;
}

.htCore .htUIInput {
    border: 1px solid var(--hot-border-color);
    background-color: var(--hot-background-color);
    color: var(--hot-text-color);
}

/* Custom button styles */
.btn-custom {
    background-color: var(--hot-primary-color);
    color: var(--hot-text-color);
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
}

/* Styles for the surrounding divs, headers, etc. */
.container-header {
    color: var(--hot-text-color);
    margin-bottom: 10px;
}

h3 {
    color: white;
}