/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #292929;
    /* Match with your app's color scheme */
    color: #fff;
}

/* Removed flex: 1 from .title to allow it to be centered properly */
.title {
    text-align: center;
}

.user-info {
    display: flex;
    align-items: center;
}

.username {
    margin-right: 1rem;
    /* Space between username and logout button */
}

.logout-button {
    padding: 0.5rem 1rem;
    background-color: #352481;
    /* Slightly lighter gray for the button */
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

/* New class for spacers */
.spacer {
    flex: 1;
}